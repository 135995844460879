import { ErrorTypes } from './enums';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logger = (message: string, errorType: ErrorTypes = ErrorTypes.Error, context: any = undefined): void => {
  switch (errorType) {
    case ErrorTypes.Warn: {
      console.warn(message, context);
      break;
    }
    case ErrorTypes.Debug: {
      console.log(message, context);
      break;
    }
    default: {
      console.error(message, context);
      break;
    }
  }
};
export default logger;
