export type StringORUndefined = string | undefined;

// @todo: Assess this list
export interface IConfig {
  ASSETS_URL: StringORUndefined,
  AUTHX_AUTHORIZE_BASE_URL: StringORUndefined,
  AUTHX_AUTHORIZE_IDP_TITLE: StringORUndefined, // may be not used
  AUTHX_AUTHORIZE_SPOTIFY_IDP_TITLE: StringORUndefined,
  AUTHX_AUTHORIZE_SPOTIFY_STREAM_IDP_TITLE: StringORUndefined,
  AUTHX_AUTHORIZE_DEEZER_IDP_TITLE: StringORUndefined,
  BUILDER_API_KEY: StringORUndefined,
  IFB_ACQUISITION_SYS: StringORUndefined,
  IFB_CLIENT_ID: StringORUndefined
  IFB_SERVICE_URL: StringORUndefined,
  IFBSILO_ACQUISITION_SYS: StringORUndefined,
  IFBSILO_CLIENT_ID: StringORUndefined,
  IFBSILO_URL: StringORUndefined,
  IFB_V2_ACQUISITION_SYS: StringORUndefined,
  IFB_V2_CLIENT_ID: StringORUndefined,
  IFB_V2_SERVICE_URL: StringORUndefined,
  MUSICKIT_DEVELOPER_TOKEN: StringORUndefined,
  MUSICKIT_SERVICE_URL: StringORUndefined,
  PRESAVE_APP_ID: StringORUndefined,
  PRESAVE_SERVICE_URL: StringORUndefined,
  SPOTIFY_SDK_SCRIPT_URL: StringORUndefined,
  SPOTIFY_SERVICE_URL: StringORUndefined,
  DEEZER_USER_INFO_URL: StringORUndefined,
  UGC_IMAGE_URL: StringORUndefined,
  UGC_PRIVATE_UPLOAD_S3_URL: StringORUndefined,
  UGC_PRIVATE_UPLOAD_APPID: StringORUndefined,
  UGC_PROMO_URL: StringORUndefined,
  VICEVERSA_SERVICE_URL: StringORUndefined,
  GOOGLE_TAG_MANAGER_ID: StringORUndefined,
  GTM_DATA_COLLECTOR_URL: StringORUndefined,
  PROMO_FILE_UPLOAD_SIZE_LIMIT_MB: StringORUndefined,
  POLL_WIDGET_SERVICE_URL: StringORUndefined,
  ASYNC_STATS_DATA_COLLECTOR_ENABLED: StringORUndefined,
}

export const config: IConfig = {
  ASSETS_URL: process.env.ASSETS_URL,
  AUTHX_AUTHORIZE_BASE_URL: process.env.AUTHX_AUTHORIZE_BASE_URL,
  AUTHX_AUTHORIZE_IDP_TITLE: process.env.AUTHX_AUTHORIZE_IDP_TITLE,
  AUTHX_AUTHORIZE_SPOTIFY_IDP_TITLE: process.env.AUTHX_AUTHORIZE_SPOTIFY_IDP_TITLE,
  AUTHX_AUTHORIZE_SPOTIFY_STREAM_IDP_TITLE: process.env.AUTHX_AUTHORIZE_SPOTIFY_STREAM_IDP_TITLE,
  AUTHX_AUTHORIZE_DEEZER_IDP_TITLE: process.env.AUTHX_AUTHORIZE_DEEZER_IDP_TITLE,
  BUILDER_API_KEY: process.env.BUILDER_API_KEY,
  IFB_CLIENT_ID: process.env.IFB_CLIENT_ID,
  IFB_SERVICE_URL: process.env.IFB_SERVICE_URL,
  IFBSILO_ACQUISITION_SYS: process.env.IFBSILO_ACQUISITION_SYS,
  IFBSILO_CLIENT_ID: process.env.IFBSILO_CLIENT_ID,
  IFBSILO_URL: process.env.IFBSILO_URL,
  IFB_V2_ACQUISITION_SYS: process.env.IFB_V2_ACQUISITION_SYS,
  IFB_V2_CLIENT_ID: process.env.IFB_V2_CLIENT_ID,
  IFB_V2_SERVICE_URL: process.env.IFB_V2_SERVICE_URL,
  MUSICKIT_DEVELOPER_TOKEN: process.env.MUSICKIT_DEVELOPER_TOKEN,
  MUSICKIT_SERVICE_URL: process.env.MUSICKIT_SERVICE_URL,
  PRESAVE_APP_ID: process.env.PRESAVE_APP_ID,
  PRESAVE_SERVICE_URL: process.env.PRESAVE_SERVICE_URL,
  SPOTIFY_SDK_SCRIPT_URL: process.env.SPOTIFY_SDK_SCRIPT_URL,
  SPOTIFY_SERVICE_URL: process.env.SPOTIFY_SERVICE_URL,
  DEEZER_USER_INFO_URL: process.env.DEEZER_USER_INFO_URL,
  UGC_IMAGE_URL: process.env.UGC_IMAGE_URL,
  UGC_PRIVATE_UPLOAD_S3_URL: process.env.UGC_PRIVATE_UPLOAD_S3_URL,
  UGC_PRIVATE_UPLOAD_APPID: process.env.UGC_PRIVATE_UPLOAD_APPID,
  UGC_PROMO_URL: process.env.UGC_PROMO_URL,
  VICEVERSA_SERVICE_URL: process.env.VICEVERSA_SERVICE_URL,
  GOOGLE_TAG_MANAGER_ID: process.env.GOOGLE_TAG_MANAGER_ID,
  GTM_DATA_COLLECTOR_URL: process.env.GTM_DATA_COLLECTOR_URL,
  PROMO_FILE_UPLOAD_SIZE_LIMIT_MB: process.env.PROMO_FILE_UPLOAD_SIZE_LIMIT_MB,
  POLL_WIDGET_SERVICE_URL: process.env.POLL_WIDGET_SERVICE_URL,
  ASYNC_STATS_DATA_COLLECTOR_ENABLED: process.env.ASYNC_STATS_DATA_COLLECTOR_ENABLED
};
